<script>
import Vue from 'vue';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  async beforeRouteEnter(to, from, next) {
    const jobs = await Vue.ovReq.get('deviceIdsBlacklist/getList');
    next((vm) => {
      vm.jobs = jobs;
      return vm;
    });
  },
  data() {
    return {
      isUploading: false,
      filesCount: 0,
      jobs: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_BASE_URL}api/admin/deviceIdsBlacklist/uploadFile`,
        thumbnailWidth: 150,
        maxFilesize: 2048, // MB
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        autoProcessQueue: false,
        maxFiles: 2,
        acceptedFiles: '.zip',
        addRemoveLinks: true,
        timeout: 0, // this fucked up shit should be default (docs said),
        // but its not, spent a lot of time figuring out, why the request was getting canceled
        // DO NOT TOUCH THIS TIMEOUT UNLESS U KNOW WHAT U DOING
      },
    };
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  mounted() {
    window.addEventListener('beforeunload', (event) => {
      if (this.isUploading) {
        event.preventDefault();
        event.returnValue = '';
      }
    });
  },
  methods: {
    async uploadFile() {
      this.$refs.dropzone.processQueue();
    },
    onMaxFilesReached(file) {
      this.$refs.dropzone.removeAllFiles();
      this.$refs.dropzone.addFile(file[1]);
    },
    fileAdded() {
      this.filesCount = 1;
    },
    fileRemoved() {
      this.filesCount = 0;
    },
    async onSuccess() {
      this.$ovNotify.success('File uploaded successfully');

      const jobs = await Vue.ovReq.get('deviceIdsBlacklist/getList');
      this.jobs = jobs;
    },
  },
};
</script>

<template lang="pug">
	.widget
		.widget-header
			h1.title Global Devices Blacklist
		.widget-body
			h4.widget-subtitle Upload additional device ids
				i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="Every device id within this list will be blocked on offers that are marked so in the Data Control")

			vue-dropzone.mb-3(
				@vdropzone-complete="isUploading = false"
				@vdropzone-sending="isUploading = true"
				@vdropzone-canceled="isUploading = false"
				@vdropzone-max-files-reached="onMaxFilesReached"
				@vdropzone-file-added="fileAdded"
				@vdropzone-removed-file="fileRemoved"
				@vdropzone-success="onSuccess"
				ref="dropzone"
				:options="dropzoneOptions"
				id="dropzone"
				:useCustomSlot="true"
			)
				.dropzone-custom-content(v-if="filesCount === 0")
					h3.dropzone-custom-title Drag and drop to upload zip archive
					.subtitle or click to select a file from your computer
			button.mb-3.btn.btn-primary(@click="uploadFile", :disabled="isUploading || filesCount === 0") Update blacklist

			table.table.table-bordered
				thead
					tr
						th Filename
						th Upload date
						th User
						th Devices count
						th Status
				tbody
					tr(v-for="job in jobs" :key="job.id")
						td {{ job.filename }}
						td {{ job.created_at }}
						td {{ job.user }}
						td {{ job.ids_count | numberCommas }}
						td {{ job.status }}
</template>

<style lang="scss" scoped>
.dropzone-custom-title {
  margin-top: 0;
  color: #00b782;
}

.subtitle {
  color: #314b5f;
}

.widget::v-deep .dz-progress {
  left: 5%;
  width: 90%;
  margin-left: 0;
}

.widget::v-deep .dz-file-preview {
  left: 2.5%;
  width: 95%;
  margin-left: 0;
}
</style>
